<template>
  <div v-if="model" class="video-main-div">
    <video v-show="showBlurVideo" preload="auto" crossorigin="" id="backgroundVideo">
      <source />
      Your browser does not support HTML5 video.
    </video>

    <div class="new-content">
      <video preload="auto" crossorigin="" id="myVideo" @ended="finished" @error="error">
        <source />
        Your browser does not support HTML5 video.
      </video>
      <canvas id="canvas" hidden></canvas>
      <div class="content" v-html="model.description"></div>
    </div>

  </div>
</template>

<script>
import store from '../store/index';
export default {
  name: 'background-video',
  props: ['data'],
  mounted() {
    try {
      this.playVideo();
      this.timeoutTimer = setTimeout(() => {
        //window.console.log('video timer started');
        if (!this.destroyed) {
          window.console.log('screen is probably in black screen');
          this.triggerNext('timeoutTimer');
        }
      }, 10 * 1000);
    } catch (error) {
      window.console.log(error);
      this.triggerNext('mounted');
    }
  },
  data() {
    return {
      destroyed: false,
      timeoutTimer: null,
      showBlurVideo: false,
      nextTriggered: false,
      timeoutTimer2: null
    };
  },
  methods: {
    triggerNext(from) {
      //window.console.log('triggerd next', this.nextTriggered);
      window.console.log('triggerd next from ------------- ', from);
      if (this.timeoutTimer)
        clearTimeout(this.timeoutTimer);
      if (this.timeoutTimer2)
        clearTimeout(this.timeoutTimer2);

      if (!this.nextTriggered) {
        this.nextTriggered = true;
        store.dispatch('setNextPlaylist')
      }
    },
    finished() {
      if (this.model.isReklam === true && this.model.id) {
        // register reklam to accumator so we can send the reklam has shown
      }
      this.triggerNext('finished');
    },
    error() {
      this.triggerNext('error');
    },
    playVideo() {
      try {
        var video = document.getElementById('myVideo');
        video.src = this.model.fileName;
        video.type = this.model.fileType;
        video.muted = !this.model.videoSound;

        video.onloadedmetadata = () => {
          //window.console.log('metadata loaded!', this.model.fileName);
          //window.console.log(video.duration);//this refers to myVideo
          var width = video.videoWidth,
            height = video.videoHeight;
          if (width < 1800 || height < 900) {
            this.showBlurVideo = true;
            this.playBackgroundVideo();
          }
          clearTimeout(this.timeoutTimer);
          var timeoutSec = 30;
          if (video.duration) {
            timeoutSec = video.duration + 3;
          }

          this.timeoutTimer2 = setTimeout(() => {
            //window.console.log('video time out', this.destroyed);
            if (!this.destroyed) {
              //window.console.log('video is probably in black screen');
              this.triggerNext('timeoutTimer2');
              return;
            }
          }, timeoutSec * 1000);
        };

        var playPromise = video.play();
        if (playPromise === undefined) {
          this.triggerNext('playPromise undefined');
          return;
        }

        playPromise
          .then(() => { })
          .catch(error => {
            window.console.log(error);
            this.triggerNext('Video Error');
            return;
          });

      } catch (error) {
        window.console.log(error);
        this.triggerNext('catch (error)');
      }
    },
    playBackgroundVideo() {
      try {
        var backgroundVideo = document.getElementById('backgroundVideo');
        backgroundVideo.src = this.model.fileName;
        backgroundVideo.type = this.model.fileType;
        backgroundVideo.muted = true;

        var backgroundVideoPlayPromise = backgroundVideo.play();
        if (backgroundVideoPlayPromise === undefined) {
          this.triggerNext('backgroundVideoPlayPromise');
          return;
        }

        backgroundVideoPlayPromise
          .then(() => { })
          .catch(error => {
            window.console.log('backgroundVideoPlayPromise Error: ' + error);
            this.triggerNext('backgroundVideoPlayPromise Error');
          });

      } catch (error) {
        window.console.log(error);
        this.triggerNext('backgroundVideoPlayPromise catch (error)');
      }
    }
  },
  computed: {
    model() {
      return this.$store.state.backgroundVideo;
    },
  },
  beforeDestroy() {
    this.destroyed = true;
  }
};
</script>

<style lang="scss" scoped>
.video-main-div {
  position: initial;
  width: 100%;
  height: 100%;
  text-align: center;
}

#myVideo {
  height: 100%;
  margin: auto;
  max-width: 100%;
}

.content {
  width: 100%;
  height: 100%;
  padding: 20px;
  position: absolute;
  top: 0px;
  color: #f1f1f1;
}


#backgroundVideo {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  filter: blur(15px);
}

.new-content {
  position: absolute;
  bottom: 0;
  background: rgba(58, 58, 58, 0.5);
  color: #f1f1f1;
  width: 100%;
  text-align: center;
  height: 100%;
}
</style>
